export const useAbonnement = defineStore("abonnementStore", () => {
    const userData = useDirectusUser();
    const { getItems, updateItem, createItems, getItemById, deleteItems } = useDirectusItems();

    const abonnement = ref({})

    // watch(userData, () => {
    //     if (userData.value) {
    //         getAbonnement();
    //     }
    // }, {deep: true})

    if(userData.value) getAbonnement();

    async function getAbonnement() {
        await getItems({
            collection: "abonnementen",
            params: {
                filter: {
                    klant: {
                        _eq: userData.value.id
                    }
                },
                fields: ['producten.aantal', 'producten.aantal_personen', 'producten.abonnementen_id', 'producten.frequentie', 'producten.id', 'producten.prijs', '*', 'producten.producten_id.*']
            }
        }).then(async (data:any) => {
            if (data.length > 1) {
                console.error('Multiple abonnementen found for user');
                let abonnementItems = data.filter((abonnement: any) => abonnement.status !== 'actief');
                abonnementItems = abonnementItems.filter((abonnement: any) => abonnement.eerder_geactiveerde_abonnement !== true);

                if(abonnementItems.length > 0) {
                    try {
                        await deleteItems({
                            collection: "abonnementen",
                            items: abonnementItems.map((abonnement: any) => abonnement.id)
                        })
                    } catch (error) {
                        console.error('Error deleting abonnement', error);
                    }
                }

                abonnement.value = data[0];
            } else {
                abonnement.value = data[0];
            }
        })
    }

    async function createAbonnement() {
        createItems({
            collection: "abonnementen",
            items: [{
                klant: userData.value.id,
            }]
        }).then((res) => {
            getAbonnement();
            return res[0];
        })
    }

    async function updateAbonnement(id: number, data: any) {
        await updateItem({
            collection: "abonnementen",
            id,
            data
        }).then(() => {
            getAbonnement();
        })
    }

    async function createAbonnementProduct(abonnementen_id: number, abonnementProduct: any, totalAantal: any, aantalPersonenItem: any, frequentieitem: any, abonnementProductPrijs: any){
        await createItems({
            collection: "abonnementen_producten",
            items: [{
                abonnementen_id: abonnementen_id,
                producten_id: abonnementProduct.id,
                aantal: totalAantal,
                aantal_personen: aantalPersonenItem ? aantalPersonenItem : 0,
                frequentie: frequentieitem,
                prijs: abonnementProductPrijs,
                product_data: JSON.stringify(abonnementProduct)
            }]
        }).then((res) => {
            getAbonnement().then(() => {
                calculateAndUpdateTotalPrices(abonnementen_id)
            });
            return res[0];
        })
    }

    async function calculateAndUpdateTotalPrices(abonnementen_id: number){
        let totalPrice = 0;
        let abonnementProducten = abonnement.value.producten;
        for (let i = 0; i < abonnementProducten.length; i++) {
            totalPrice += abonnementProducten[i].prijs * abonnementProducten[i].aantal;
        }
        const roundTotalPrice = totalPrice.toFixed(2);
        const roundTotalBtw = (totalPrice * 0.09).toFixed(2);

        await updateItem({
            collection: "abonnementen",
            id: abonnementen_id,
            item: {
                totaal_bedrag: roundTotalPrice,
                totaal_btw: roundTotalBtw
            }
        }).then(() => {
            getAbonnement();
        })
    }

    async function changeCartQuantity(id: number, quantity: number) {
        let abonnementProducten = abonnement.value.producten;
        const found = abonnementProducten.find((element:any) => element.id === id);
        if (found) {
            const totalAantal = found.aantal + quantity;
            await updateItem({
                collection: "abonnementen_producten",
                id,
                item: {
                    aantal: totalAantal
                }
            }).then(() => {
                getAbonnement().then(() => {
                    calculateAndUpdateTotalPrices(abonnement.value.id)
                })
            })
        }
    }

    async function setCartQuantity(id: number, quantity: number) {
        let abonnementProducten = abonnement.value.producten;
        const found = abonnementProducten.find((element:any) => element.id === id);
        if (found) {
            await updateItem({
                collection: "abonnementen_producten",
                id,
                item: {
                    aantal: quantity
                }
            }).then(() => {
                getAbonnement().then(() => {
                    calculateAndUpdateTotalPrices(abonnement.value.id)
                })
            })
        }
    }

    async function deleteAbonnementProduct(id: number) {
        await deleteItems({
            collection: "abonnementen_producten",
            items: [id]
        }).then(() => {
            getAbonnement().then(() => {
                calculateAndUpdateTotalPrices(abonnement.value.id)
            })
        })
    }

    return {
        abonnement,
        getAbonnement,
        createAbonnement,
        updateAbonnement,
        createAbonnementProduct,
        changeCartQuantity,
        setCartQuantity,
        deleteAbonnementProduct
    }
});
